import { Base64SVG, Button, EditInput, IconPicker, Input, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { Group } from '../../models/Group'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import useLocalStorage from 'use-local-storage'
import { initIcons } from '../../data'
import { useTranslation } from 'react-i18next'

interface GroupModalProps {
  group?: Group
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onEdit?(field: string, value: any): void
  onCreate?(icon: string, name: string): void
  loading: boolean
}

const GroupModal: FC<GroupModalProps> = ({ open, setOpen, group, onEdit, onCreate, loading }) => {
  const [icons] = useLocalStorage('icons', initIcons)
  const [selectedIcon, setSelectedIcon] = useState(icons[0])
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const handleCreate = () => {
    if (!selectedIcon || name == '' || !onCreate) return
    onCreate(selectedIcon.base64string, name)
  }

  const handleIconChange = (icon: any) => {
    setSelectedIcon(icon)

    if (group && onEdit) {
      onEdit('icon', icon.base64string)
    }
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-md'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {group ? t('Groups.editGroup') : t('Groups.createGroup')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6'>
        <IconPicker
          selected={group ? icons.find((i) => i.base64string === group.icon) : selectedIcon}
          setSelected={handleIconChange}
          options={icons}
        >
          <div className='flex items-center justify-center p-1'>
            <div className='rounded-full bg-[#6275A5] p-1.5 aspect-square hover:ring-1 hover:ring-offset-2 hover:ring-gray-800'>
              <Base64SVG
                base64image={group ? group.icon : selectedIcon.base64string}
                className='[&>svg]:h-6 [&>svg]:w-6 [&>svg>*]:fill-white'
              />
            </div>
          </div>
        </IconPicker>
        {group && <EditInput value={group.name} onSave={(value) => onEdit && onEdit('name', value)} />}
        {!group && (
          <>
            <Input
              id={'name'}
              type={'text'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('Groups.name') as string}
              removePencil
              classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
            />
            <div className='flex items-center justify-end gap-x-3'>
              <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
                {t('Groups.cancel')}
              </Button>
              <Button
                className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
                onClick={handleCreate}
                disabled={!selectedIcon || name == ''}
                loading={loading}
              >
                {t('Groups.create')}
              </Button>
            </div>
          </>
        )}
      </div>
    </ModalDialog>
  )
}

export default GroupModal
