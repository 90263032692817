import { useQuery } from '@tanstack/react-query'
import UsageService, { Usage } from '../../services/Usage.service'

export const useUsage = (org: string, start: string, end: string, processDefinitionKey?: string) => {
  return useQuery<Usage>({
    queryKey: ['usage', org, start, end, processDefinitionKey],
    queryFn: () => UsageService.getUsageData(org!, { start, end, processDefinitionKey }),
    enabled: !!org && !!start && !!end,
  })
}
