import { axiosMgmtInstance } from './http-common'

export interface ProcessDefinitionVersion {
  id: string
  processDefinitionId: string
  versionTag: string
  versionNumber: number
  active: boolean
  current: boolean
  startable: boolean
  versionActivationDisabled: boolean
}

export interface ProcessDefinition {
  id: string
  code: string
  type: string
  processDefinitionKey: string
  processAccessId?: string
  color?: string
  icon?: string
  name: string
  versions?: ProcessDefinitionVersion[]
  position: number
}

class ProcessesService {
  async list(
    organization: string,
    params?: {
      search?: string
      rootOnly?: boolean
      type?: boolean
      versions?: boolean
      hideDeactivatedVersions?: boolean
    },
  ): Promise<ProcessDefinition[]> {
    return (await axiosMgmtInstance.get(`/${organization}/processes`, { params })).data
  }
}

export default new ProcessesService()
