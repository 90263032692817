import React, { FC } from 'react'
import { Button, EditInput } from '@sistemiv/s-components'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { CustomAttribute } from './OrgChartsTreeView'

interface DetailsProps {
  nodeName?: string
  attributes: CustomAttribute[]
  show?: boolean
  onClose: () => void
  handleEditField: any
}
const NodeDetails: FC<DetailsProps> = ({ nodeName, attributes, onClose, handleEditField }) => {
  return (
    <div className='w-[400px] rounded-[12px] border-[#E1E3EA] border h-4/5 '>
      <div className='border-[#E1E3EA] border-b h-[60px] text-gray-500 flex justify-between items-center'>
        <div className='p-5'>{nodeName}</div>
        <Button className='pt-4' onClick={onClose}>
          <ChevronRightIcon className='w-5 h-5 text-gray-500 font-bold' />
        </Button>
      </div>
      <div className='p-5 flex flex-col h-[calc(100%-60px)] overflow-auto'>
        {attributes.map((attr) => (
          <div key={attr.id} className='pb-4'>
            <p className='text-gray-400 pb-2'>{attr.key}</p>
            <EditInput value={attr.value} key={attr.id} onSave={(value) => handleEditField(value, attr.id)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default NodeDetails
