import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import React, { Dispatch, FC, Fragment, SetStateAction } from 'react'
import { Options } from './UsagePage'

const OptionsSelect: FC<{ selected: Options; setSelected: Dispatch<SetStateAction<Options>>; options: Options[] }> = ({
  selected,
  setSelected,
  options,
}) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className='relative mt-1 !width-[500px]'>
        <Listbox.Button className='relative font-semibold w-full cursor-pointer rounded-lg border-[#636A7E] !width-[800px] border-[1px] py-1 pl-4 pr-14 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 '>
          <span className='block truncate text-[#5C6375]'>{selected.name}</span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4'>
            <ChevronDownIcon className='h-4 w-4 stroke-2 text-[#5C6375]' aria-hidden='true' />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white border-[#ACB2C8] border-[1px] py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
            {options
              .filter((o) => o.id !== selected.id)
              .map((o) => (
                <Listbox.Option
                  key={o.id}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-3 text-gray-900 ${active ? 'bg-blue-50' : ''}`
                  }
                  value={o}
                >
                  <span className={`block truncate `}>{o.name}</span>
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default OptionsSelect
