import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { AccessCombobox, Button, GroupList, ModalDialog, SpinnerIcon } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'
import { AddMembersRequest } from '../../models/Group'
import { useNavigate, useParams } from 'react-router-dom'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useAddmemberToGroup } from '../../repositories/groups/mutations/group-add-member'
import { useGroupMembers } from '../../repositories/groups/group-members.repository'
import { useGroupNonMembers } from '../../repositories/groups/group-non-members.repository'
import { useRemoveMemberFromGroup } from '../../repositories/groups/mutations/group-remove-member'

interface MembersModalProps {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  groupId?: string
  groupName?: string
}

const MembersModal: FC<MembersModalProps> = ({ open, setOpen, groupId, groupName }) => {
  const { org } = useParams()
  const { data: members, isPending: isMembersLoading } = useGroupMembers(groupId)
  const { data: nonMembers, isPending: isNonMembersLoading } = useGroupNonMembers(groupId)
  const { mutate: addMemberMutation, isPending: loading } = useAddmemberToGroup()
  const { mutate: removeMemberMutation, isPending: removing } = useRemoveMemberFromGroup()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleRemove = (item: any) => {
    if (!groupId || !org || !item.id) return
    removeMemberMutation({
      organization: org,
      groupId: groupId,
      itemId: item.id,
    })
  }

  const handleAddMember = (item: any) => {
    if (!groupId || !org || !item.id) return
    const body: AddMembersRequest = {
      users: [item.id],
    }
    addMemberMutation({
      organization: org,
      groupId: groupId,
      body: body,
    })
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-md'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {`${t('Users.members')} ${groupName && t('Users.of') + groupName}`}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='space-y-4'>
        {loading || isMembersLoading || isNonMembersLoading || removing ? (
          <div className='w-full flex justify-center pt-5'>
            <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
          </div>
        ) : (
          <div>
            <GroupList
              title={t('users')!}
              onRemove={handleRemove}
              items={
                members?.members
                  ? members.members.length > 3
                    ? members?.members.slice(0, 3).map((member) => ({
                        id: member.id,
                        type: 'circled_value',
                        value: member.name,
                        icon: `${imageBase}/${member.objectId}/32`,
                      }))
                    : members?.members?.map((member) => ({
                        id: member.id,
                        type: 'circled_value',
                        value: member.name,
                        icon: `${imageBase}/${member.objectId}/32`,
                      }))
                  : []
              }
            />
            {members?.members && members?.members.length > 3 && (
              <Button onClick={() => navigate(`/${org}/groups/${groupId}`)} className='!pl-0  text-blue-500'>
                {t('Users.seeAllMembers')}
              </Button>
            )}
          </div>
        )}
        <AccessCombobox
          // topMenu={nonMembers ? nonMembers.users.length > 4 : false}
          topMenu={false}
          onChange={handleAddMember}
          options={
            nonMembers?.users?.map((member) => ({
              id: member.id,
              type: 'circled_value',
              value: member.name,
              icon: `${imageBase}/${member.objectId}/32`,
            })) ?? []
          }
          placeholder={t('Groups.addUsers') as string}
          inputPlaceholder={t('Users.search') as string}
        />
      </div>
    </ModalDialog>
  )
}

export default MembersModal
