import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DataItem from './DataItem'
import { SpinnerIcon, RangePicker } from '@sistemiv/s-components'
import { useProcessFlat } from '../../repositories/usage/process-flat.repository'
import { useUsage } from '../../repositories/usage/usage.repository'
import LineChart from './LineChart'
import OptionsSelect from './OptionsSelect'
import DoubleLineChart from './DoubleLineChart'
import TimeSeriesSelect from './TimeSeriesSelect'
import dayjs from 'dayjs'

export type Options = { id: string; name: string }

const UsagePage: FC = () => {
  const { t } = useTranslation()
  const { org } = useParams()
  const { data: processData, isLoading: isProcessesLoading } = useProcessFlat(org!)
  const processOptions: Options[] = useMemo(() => {
    const options = [{ id: 'all', name: 'All processes' }]
    processData?.forEach((process) => options.push({ id: process.processDefinitionKey, name: process.name }))
    return options
  }, [processData])
  const [selectedProcess, setSelectedProcess] = useState<any>(processOptions[0])
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('current')
  const now = useMemo(() => dayjs(), [])
  const [startDate, setStartDate] = useState<string>(now.startOf('month').format('YYYY-MM-DD'))
  const [rangeStartDate, setRangeStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<string>(now.format('YYYY-MM-DD'))
  const [rangeEndDate, setRangeEndDate] = useState<Date | undefined>()
  const { data: usageData, isLoading: isUsageLoading } = useUsage(
    org!,
    startDate ?? now.startOf('month').format('YYYY-MM-DD'),
    endDate ?? now.format('YYYY-MM-DD'),
    selectedProcess.id !== 'all' ? selectedProcess.id : undefined,
  )

  useEffect(() => {
    switch (selectedTimeFilter) {
      case '7': {
        setStartDate(now.subtract(parseInt('7'), 'days').format('YYYY-MM-DD'))
        setEndDate(now.format('YYYY-MM-DD'))
        return
      }
      case 'current': {
        setStartDate(now.startOf('month').format('YYYY-MM-DD'))
        setEndDate(now.format('YYYY-MM-DD'))
        return
      }
      case 'last': {
        setStartDate(now.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
        setEndDate(now.subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
        return
      }
      default: {
        setStartDate(now.startOf('month').format('YYYY-MM-DD'))
        setEndDate(now.format('YYYY-MM-DD'))
        return
      }
    }
  }, [selectedTimeFilter, now])

  useEffect(() => {
    if (rangeStartDate && rangeEndDate) {
      setStartDate(rangeStartDate.toISOString())
      setEndDate(rangeEndDate.toDateString())
    }
  }, [rangeStartDate, rangeEndDate])

  function formatStorageData(gbValue: number) {
    if (gbValue >= 1) return `${gbValue.toFixed(2)} GB`
    const mbValue = gbValue * 1024 // Pretvaranje GB u MB

    if (mbValue >= 1) {
      return `${mbValue.toFixed(2)} MB`
    } else {
      const kbValue = mbValue * 1024 // Pretvaranje MB u KB
      return `${kbValue.toFixed(2)} KB`
    }
  }

  if (isProcessesLoading || isUsageLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='p-8 pt-6 h-full overflow-auto bg-slate-200 gap-y-6 flex flex-col'>
      <h1 className='text-2xl'>{t('usage')}</h1>
      <div className='flex flex-col'>
        <p className='font-semibold text-sm mb-2 leading-5 tracking-tight'>{t('Usage.timeOptions.current')}</p>
        <div className='flex gap-x-8'>
          <DataItem title={t('Usage.activities')} value={usageData?.activitiesCount ?? 0} />
          <DataItem
            title={t('Usage.storage')}
            value={usageData?.maxStorageUsed ? formatStorageData(usageData?.maxStorageUsed) : '0 GB'}
          />
          <DataItem title={t('Usage.users')} value={usageData?.maxUsers ?? 0} />
          <DataItem title={t('Usage.serviceAcc')} value={usageData?.maxServiceUsers ?? 0} />
        </div>
      </div>
      <div className='flex gap-x-8 items-end'>
        <TimeSeriesSelect selectedTimeFilter={selectedTimeFilter} setSelectedTimeFilter={setSelectedTimeFilter} />
        {selectedTimeFilter === 'custom' && (
          <RangePicker
            startDate={rangeStartDate}
            endDate={rangeEndDate}
            onRangeChange={(startDate, endDate) => {
              setRangeStartDate(startDate)
              setRangeEndDate(endDate)
            }}
            className='[&>div>input]:!border [&>div>input]:!border-solid [&>div>input]:!border-gray-500 [&>div>input]:rounded-md [&>div>input]:shadow-sm  text-[#5C6375] font-semibold w-[330px]'
          />
        )}
      </div>
      <div className='min-h-[700px] min-w-[960px] w-full lg:w-[90%] max-w-[1980px] rounded-md p-3 bg-white'>
        <div className='flex justify-between p-4 items-center'>
          <p className='text-[16px] font-semibold leading-[21.79px] tracking-[-0.03em] text-[#636A7E]'>
            {t('Usage.activities')}
          </p>
          <OptionsSelect selected={selectedProcess} setSelected={setSelectedProcess} options={processOptions} />
        </div>
        <LineChart data={usageData?.activities ?? []} label='Activities' fill={true} />
      </div>
      <div className=' w-full lg:w-[90%] max-w-[1980px] flex gap-x-6'>
        <div className='min-h-[350px] h-fit w-[50%]  rounded-md p-3 bg-white'>
          <div className='flex justify-between p-4'>
            <p className='text-[16px] font-semibold leading-[21.79px] tracking-[-0.03em] text-[#636A7E]'>
              {t('Usage.storage')}
            </p>
          </div>
          <LineChart data={usageData?.storage ?? []} label='Storage' fill={false} />
        </div>
        <div className='min-h-[350px] h-fit w-[50%]  rounded-md p-3 bg-white'>
          <div className='flex justify-between p-4'>
            <p className='text-[16px] font-semibold leading-[21.79px] tracking-[-0.03em] text-[#636A7E]'>
              {t('Usage.users')}
            </p>
          </div>
          {usageData?.users && usageData?.serviceUsers && (
            <DoubleLineChart users={usageData.users} serviceUsers={usageData.serviceUsers} />
          )}
        </div>
      </div>
    </div>
  )
}

export default UsagePage
