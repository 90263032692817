import { useQuery } from '@tanstack/react-query'
import ProcessesService, { ProcessDefinition } from '../../services/Processes.service'

export interface ProcessSchema {
  key: string
  type: string
  icon: string
  position: number
  translationKey: string
  code: string
  color: string
  children?: ProcessSchema[]
}

export interface ProcessDefinitionCategory {
  id: string
  type: string
  color?: string
  icon: string
  name: string
  leaves: ProcessDefinition[]
  position: number
}

export type ProcessDefinitionResponse = ProcessDefinition | ProcessDefinitionCategory

export const useProcessFlat = (org: string) => {
  return useQuery<ProcessDefinition[]>({
    queryKey: ['process-definitions', org],
    queryFn: async () => {
      const data = await ProcessesService.list(org!, { versions: false })
      const flattenedItems = flatten(data)
      return flattenedItems.filter(({ type }) => type === 'ProcessItem') ?? []
    },
    enabled: !!org,
    staleTime: 0,
  })
}

const flatten = (array?: ProcessDefinitionResponse[]): any => {
  return array
    ? array.reduce<ProcessSchema[]>((acc, item) => {
        return [...acc, { ...item }, ...flatten('leaves' in item ? item.leaves : [])]
      }, [])
    : []
}
