import { axiosMeteringInstance } from './http-common'

export type TimeSeries = {
  unit: string
  value: number
  timestamp: string
}

export interface Usage {
  activitiesCount: number
  maxUsers: number
  maxServiceUsers: number
  maxStorageUsed: number
  organizationCreatedAt: string
  users: TimeSeries[]
  serviceUsers: TimeSeries[]
  storage: TimeSeries[]
  activities: TimeSeries[]
}

class UsageService {
  async getUsageData(
    organization: string,
    params: { start: string; end: string; processDefinitionKey?: string },
  ): Promise<Usage> {
    return (await axiosMeteringInstance.get(`/${organization}/usage`, { params })).data
  }

  // async getActivitiesData(organization: string, key: string, params: { month: string }): Promise<TimeSeries[]> {
  //   return (await axiosAnalyticsInstance.get(`/${organization}/usage/${key}/activities`, { params })).data
  // }
}

export default new UsageService()
