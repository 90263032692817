import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartOptions,
  Title,
} from 'chart.js'
import { TimeSeries } from '../../services/Usage.service'

// Registruj sve potrebne komponente pre nego što ih koristiš
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title)

type LineChartProps = {
  users: TimeSeries[]
  serviceUsers: TimeSeries[]
}

const DoubleLineChart: React.FC<LineChartProps> = ({ users, serviceUsers }) => {
  const userValues = users.map((entry) => entry.value)
  const serviceUserValues = serviceUsers.map((entry) => entry.value)

  // Definisanje podataka za Chart.js
  const data = {
    labels: users.map((item) =>
      new Date(item.timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    ),
    datasets: [
      {
        label: 'Users',
        data: userValues,
        borderColor: 'rgba(168, 149, 80, 1)',
        tension: 0,
        pointRadius: userValues.length > 1 ? 0 : 5,
      },
      {
        label: 'Service Users',
        data: serviceUserValues,
        borderColor: 'rgba(46, 119, 229, 1)',
        tension: 0,
        pointRadius: serviceUserValues.length > 1 ? 0 : 5,
      },
    ],
  }

  const options: ChartOptions<'line'> = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      tooltip: {
        mode: 'index', // Validna vrednost
        intersect: false,
      },
    },
    scales: {
      x: {
        // title: {
        //   display: true,
        //   text: 'Date',
        // },
      },
      y: {
        // title: {
        //   display: true,
        //   text: 'Value',
        // },
        beginAtZero: true,
      },
    },
  }

  return <Line data={data} options={options} />
}

export default DoubleLineChart
