import React, { FC } from 'react'

const DataItem: FC<{ title: string; value: string | number }> = ({ title, value }) => {
  return (
    <div className='w-[350px] h-[124px] gap-[6px] rounded-[6px] border-[2px] border-[#ACB2C8] flex flex-col justify-between p-4 text-ellipsis'>
      <p className='text-[16px] font-semibold leading-[21.79px] tracking-[-0.03em] text-[#636A7E]'>{title}</p>
      <p className='text-[44px] font-medium leading-[72px] tracking-[-0.03em] text-[#3F74FF]'>{value}</p>
    </div>
  )
}

export default DataItem
