import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartOptions,
  Title,
  Filler,
} from 'chart.js'

// Registruj sve potrebne komponente pre nego što ih koristiš
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Filler, Tooltip, Legend, Title)

interface DataPoint {
  unit: string
  value: number
  timestamp: string
}

interface LineChartProps {
  data: DataPoint[]
  label: string
  fill: boolean
}

const LineChart: React.FC<LineChartProps> = ({ data, label, fill }) => {
  const chartData = {
    labels: data.map((item) =>
      new Date(item.timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    ),
    datasets: [
      {
        label: `${label} (${data[0]?.unit || 'Unit'})`,
        data: data.map((item) => item.value),
        borderColor: 'rgba(46, 119, 229, 1)',
        backgroundColor: 'rgba(46, 119, 229, 0.2)',
        borderWidth: 2,
        pointRadius: data.length > 1 ? 0 : 5,
        fill: fill,
      },
    ],
  }

  const options: ChartOptions<'line'> = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      tooltip: {
        mode: 'index', // Validna vrednost
        intersect: false,
      },
    },
    scales: {
      x: {
        // title: {
        //   display: true,
        //   text: 'Date',
        // },
      },
      y: {
        // title: {
        //   display: true,
        //   text: 'Value',
        // },
      },
    },
  }

  return (
    // <div className='w-full h-full'>
    <Line data={chartData} options={options} className='!max-h-[90%]' />
    // </div>
  )
}

export default LineChart
