import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import OrgChartsHeader from './OrgChartsHeader'
import AddOrgPositionModal from './AddOrgPositionModal'
import OrgChartsTreeView, { OrgPosition } from './OrgChartsTreeView'
import EditOrgPositionModal from './EditOrgPositionModal'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Button, DeleteModal, SpinnerIcon, TabPanel, Tabs } from '@sistemiv/s-components'
import OrgMembersModal from './OrgMembersModal'
import { useOrganizationNodes } from '../../repositories/organization-nodes/organization-nodes.repository'
import { useAddOrgPosition } from '../../repositories/organization-nodes/mutations/organization-nodes-add.mutation'
import { useParams } from 'react-router-dom'
import { useRemoveOrgPosition } from '../../repositories/organization-nodes/mutations/organization-nodes-remove.mutation'
import { useUpdateOrgPosition } from '../../repositories/organization-nodes/mutations/organization-nodes-update.mutation'
import { useTranslation } from 'react-i18next'
import { useCustomFields } from '../../repositories/custom-fields/customFields.respository'
import CustomFields from './custom-fields/CustomFields'
import { useUpdateFieldValue } from '../../repositories/custom-fields/mutations/organization-nodes-update-field.mutation'
import NodeDetails from './NodeDetails'
import { nodesEvents, SignalRContext } from '../../pages/Dashboard'
import { useQueryClient } from '@tanstack/react-query'

type Tab = {
  key: 'config' | 'custom-fields'
  value: string
}

const OrgChartsPage: FC = () => {
  const { org } = useParams()
  const [addOrgPositionOpen, setAddOrgPositionOpen] = useState(false)

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [membersModalOpen, setMembersModalOpen] = useState(false)
  const [showFields, setShowFields] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: orgPositions, isLoading, isFetching } = useOrganizationNodes()
  const { data: customFields, isLoading: isFieldsLoading, isFetching: isFieldsFetching } = useCustomFields()
  const { mutate: addOrgPosition, isPending: isAdding } = useAddOrgPosition()
  const { mutate: removeOrgPosition, isPending: isRemoving } = useRemoveOrgPosition()
  const { mutate: updateOrgPosition, isPending: isMoving } = useUpdateOrgPosition()
  const { mutate: updateFieldValue } = useUpdateFieldValue()
  const [editingOrgPosition, setEditingOrgPosition] = useState<OrgPosition | undefined>()
  const [selectedPosition, setSelectedPosition] = useState<OrgPosition | undefined>(orgPositions && orgPositions[0])
  const nodeDetailsRef = useRef<HTMLDivElement | null>(null)

  const findNodeById = useCallback((tree, id) => {
    // Proveri da li trenutni čvor ima traženi id
    if (tree.id === id) {
      return tree
    }

    // Ako ima dece, rekurzivno pretraži svako dete
    if (tree.children) {
      for (const child of tree.children) {
        const result = findNodeById(child, id)
        if (result) {
          return result
        }
      }
    }

    // Ako se ništa ne pronađe, vrati null
    return null
  }, [])

  const attributes = useMemo(() => {
    if (!orgPositions) return []
    else {
      let node
      if (!selectedPosition) {
        setSelectedPosition(orgPositions[0])
      } else node = findNodeById(orgPositions[0], selectedPosition.id)
      return node ? node.attributes : []
    }
  }, [orgPositions, selectedPosition, findNodeById])

  const tabs: Tab[] = useMemo(
    () => [
      { key: 'config', value: t('OrgChart.config') },
      { key: 'custom-fields', value: t('OrgChart.customFields') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [t, tabs])

  const handleAddOrgPosition = (name: string) => {
    if (!org || !editingOrgPosition) return
    addOrgPosition(
      { organization: org, parentId: editingOrgPosition.id, name },
      {
        onSuccess: () => setAddOrgPositionOpen(false),
      },
    )
  }

  const handleEdit = (name: string) => {
    if (!org || !editingOrgPosition) return
    updateOrgPosition(
      { organization: org, nodeId: editingOrgPosition.id, name },
      {
        onSuccess: () => setEditModalOpen(false),
      },
    )
  }

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload) => {
      if (nodesEvents.includes(payload)) {
        queryClient.invalidateQueries({ queryKey: ['organization-nodes', org] })
      }
    },
    [],
  )

  const handleDelete = () => {
    if (!org || !editingOrgPosition) return
    removeOrgPosition(
      { organization: org, nodeId: editingOrgPosition.id },
      {
        onSuccess: () => setDeleteModalOpen(false),
      },
    )
  }

  const handleCloseDetails = () => {
    setShowFields(false)
  }

  const handleDetailsClicked = (position: OrgPosition) => {
    setSelectedPosition(position)
    setShowFields(true)
    setTimeout(() => {
      nodeDetailsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 100)
  }

  const handleAddOnOrgPosition = (position: OrgPosition) => {
    setEditingOrgPosition(position)
    setAddOrgPositionOpen(true)
  }

  const handleEditOrgPosition = (position: OrgPosition) => {
    setEditingOrgPosition(position)
    setEditModalOpen(true)
  }

  const handleOnMembersOpen = (position: OrgPosition) => {
    setEditingOrgPosition(position)
    setMembersModalOpen(true)
  }

  const handleDeleteOrgPosition = (position: OrgPosition) => {
    setEditingOrgPosition(position)
    setDeleteModalOpen(true)
  }

  const handleEditField = (name: string, id: string) => {
    if (!org || !selectedPosition) return
    updateFieldValue({ organization: org, nodeId: selectedPosition.id, id, name })
  }

  const loading = isFetching || isLoading || isAdding || isRemoving || isMoving || isFieldsFetching || isFieldsLoading

  return (
    <div className='h-full overflow-auto'>
      <OrgChartsHeader
        onAddClick={() => orgPositions && !!orgPositions.length && handleAddOnOrgPosition(orgPositions[0])}
      />
      <div className={`relative px-8 h-full py-5 ${loading ? 'opacity-50' : 'opacity-100'}`}>
        {loading && (
          <div className='absolute flex items-center justify-center h-full w-2/3 !opacity-100'>
            <SpinnerIcon className='w-7 h-7 text-sky-500' />
          </div>
        )}
        <Tabs
          active={activeTab.value}
          tabs={tabs.map((tab) => tab.value)}
          onClick={(string) => {
            setActiveTab(tabs.find((tab) => tab.value === string)!)
          }}
          childrenClassName='!pt-0'
        >
          <TabPanel tab={tabs[0].value} active={activeTab.value}>
            <div className='flex justify-between h-full w-full py-8'>
              <OrgChartsTreeView
                orgPositions={orgPositions ?? []}
                rootNode={orgPositions?.[0]}
                onAddClicked={handleAddOnOrgPosition}
                onEditClicked={handleEditOrgPosition}
                onDeleteClicked={handleDeleteOrgPosition}
                onMembersClicked={handleOnMembersOpen}
                onDetailsClicked={handleDetailsClicked}
                selected={selectedPosition}
              />
              {attributes &&
                attributes.length > 0 &&
                (showFields ? (
                  <>
                    <div ref={nodeDetailsRef}></div>
                    <NodeDetails
                      nodeName={selectedPosition?.name}
                      attributes={attributes}
                      show={showFields}
                      onClose={handleCloseDetails}
                      handleEditField={handleEditField}
                    />
                  </>
                ) : (
                  <div className='w-[54px] rounded-[12px] border-[#E1E3EA] border h-4/5'>
                    <Button
                      className='pt-4'
                      onClick={() => {
                        setShowFields(true)
                      }}
                    >
                      <ChevronLeftIcon className='w-5 h-5 text-gray-500 font-bold' />
                    </Button>
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel tab={tabs[1].value} active={activeTab.value}>
            <CustomFields data={customFields} />
          </TabPanel>
        </Tabs>
      </div>
      {addOrgPositionOpen && (
        <AddOrgPositionModal
          open={addOrgPositionOpen}
          setOpen={setAddOrgPositionOpen}
          onAdd={handleAddOrgPosition}
          loading={isAdding}
        />
      )}
      {editModalOpen && editingOrgPosition && (
        <EditOrgPositionModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          orgPosition={editingOrgPosition}
          onEdit={handleEdit}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('OrgChart.deletePosition')}
          description={t('OrgChart.deletePositionMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
          isLoading={isRemoving}
        />
      )}
      {membersModalOpen && (
        <OrgMembersModal
          open={membersModalOpen}
          setOpen={setMembersModalOpen}
          orgPosition={editingOrgPosition ?? undefined}
        />
      )}
    </div>
  )
}

export default OrgChartsPage
