import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Button, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useEffect, useState } from 'react'
import { GroupMember, GroupNonMembersResponse } from '../../models/Group'
import { useParams } from 'react-router-dom'
import GroupService from '../../services/GroupService'
import MemberPillCombobox from './MemberPillCombobox'
import { useTranslation } from 'react-i18next'

interface AddMemberModalProps {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?(ids: string[]): void
}
const AddMemberModal: FC<AddMemberModalProps> = ({ open, setOpen, onAdd }) => {
  const { org, id } = useParams()
  const [nonMembers, setNonMembers] = useState<GroupNonMembersResponse | null>(null)
  const [selectedMembers, setSelectedMembers] = useState<GroupMember[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (!org || !id) return
    GroupService.listNonMembers(org, id).then((response) => {
      if (response.total > 20) {
        GroupService.listNonMembers(org, id, 0, response.total).then(setNonMembers)
      } else {
        setNonMembers(response)
      }
    })
  }, [org, id])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-md'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('Users.addMembers')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='pt-5 space-y-6'>
        <MemberPillCombobox options={nonMembers?.users ?? []} value={selectedMembers} onChange={setSelectedMembers} />
        <div className='flex items-center justify-end gap-x-3'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Groups.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() => onAdd && onAdd(selectedMembers?.map((m) => m.id))}
            disabled={selectedMembers.length === 0}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddMemberModal
